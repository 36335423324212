<template>
  <router-view />
</template>
<script>
import { get } from 'lodash'
import { useStore } from 'vuex'
import { useMeta } from 'quasar'
import { defineComponent } from 'vue'
import bootMixins from 'mixins/boot'
import validationMixins from 'hc-core/mixins/validation.js'
import { DEFAULT_SEO } from 'hc-core/composables/seo'
import { analyticsSender } from 'hc-core/composables/logger'

import { $t } from 'hc-core/composables/intl'
import stelace from 'hc-core/composables/stelace'
import { useSession } from 'hc-core/composables/useSession'

export default defineComponent({
  name: 'App',
  mixins: [bootMixins, validationMixins],
  preFetch ({ store }) {
    return store.dispatch('common/initApp')
  },
  setup () {
    useMeta(() => { return get(useStore(), 'state.content.seo', DEFAULT_SEO) })
  },
  created () {
    this.$q.iconMapFn = (iconName) => {
      if (iconName.startsWith('uil:') === true) return { cls: `uil uil-${iconName.substring(4)}` }
    }
  },
  mounted () {
    if (process.env.CLIENT && !process.env.DEV) this.analyticsLoop()
    this.setExpirationHandling()
  },
  onBeforeUnmount () {
    if (process.env.CLIENT && !process.env.DEV) analyticsSender({ store: this.$store })
  },
  methods: {
    analyticsLoop () {
      try {
        analyticsSender({ store: this.$store })
        setTimeout(() => { this.analyticsLoop() }, 120 * 1000)
      } catch (e) { }
    },
    setExpirationHandling () {
      const session = useSession()
      stelace.onError('userSessionExpired', async () => {
        session.purge()
        this.notifyError(
          'error.user_session_expired',
          {
            timeout: 0,
            closeBtn: null,
            actions: [
              {
                label: $t('authentication.log_in_button'),
                color: 'black',
                handler: () => {
                  window.open(
                    this.$app({ name: 'login' }),
                    '_self'
                  )
                }
              }
            ]
          }
        )
      })
    }
  }
})
</script>
