<template>
  <QPage padding>
    <!-- Header -->
    <div class="q-mx-auto stl-content-container stl-content-container--small q-mt-xl q-pt-xl relative-position">
      <div class="flex column items-center justify-center text-center">
        <AppContent
          tag="h1"
          class="gt-xs q-ma-none text-h2 text-weight-bold hc-text-gradient"
          path="pages.home.title"
        />
        <AppContent
          tag="h1"
          class="lt-sm q-ma-none text-h2 text-weight-bold hc-text-gradient"
          path="pages.home.title_mobile"
        />
        <AppContent
          class="text-title1 q-mt-md text-grey-6 text-weight-medium"
          path="pages.home.subtitle"
        />
        <QInput
          v-model="query"
          class="q-my-xl shadowed"
          label-color="grey-5"
          bg-color="white"
          type="text"
          size="xl"
          outlined
          rounded
          style="width:100%; max-width:450px;"
          :label="$t('pages.home.input')"
          @keydown.enter.prevent="fireSearch(false)"
        >
          <template #append>
            <QIcon
              name="uil:search"
              class="cursor-pointer"
              @click="fireSearch(false)"
            />
          </template>
        </QInput>
      </div>
    </div>

    <!-- Offers -->
    <div
      v-if="$_.get(frontpageData, 'offers[0]', false)"
      class="q-mx-auto stl-content-container stl-content-container--xlarge q-mt-xl relative-position"
    >
      <div class="flex column items-center justify-center">
        <AppContent
          tag="h2"
          class="q-ma-none text-h4 text-weight-bold q-mt-xl text-center"
          path="pages.home.offers_title"
        />
        <AppContent
          class="text-subtitle2 text-grey-6 q-mt-sm text-center"
          path="pages.home.offers_subtitle"
        />
        <div class="q-mt-md q-mb-xl row q-pt-md full-width">
          <div
            v-for="offer of frontpageData.offers"
            :key="offer.id"
            class="col-12 col-lg-3 col-md-4 col-sm-6 q-pa-xs"
          >
            <OfferCard
              class="hc-link"
              :asset="offer"
              :company="offer.company"
            />
          </div>
          <div
            v-if="landingRecruitAd"
            class="col-12 col-lg-3 col-md-4 col-sm-6 q-pa-xs"
          >
            <QCard
              flat
              class="br-25 bd-1 bd-grey-4 hc-shadow text-center"
              style="height:100%; max-height: 100%; overflow:hidden;"
              @click="handleAdClick(landingRecruitAd)"
            >
              <div :style="`min-height:150px; height:100%; max-height:inherit; background: url(${cdnImg(landingRecruitAd.fields.media)}) center center / cover;`" />
              <ActionTooltip
                v-if="landingRecruitAd.fields.label"
                :str="landingRecruitAd.fields.label"
              />
            </QCard>
          </div>
        </div>
        <AppContent
          class="text-body1 text-grey-6 q-my-md text-center"
          path="pages.home.offers_cta"
        />
        <HCBigButton
          label="pages.home.offers_button"
          :to="{ name: 'searchOffers' }"
        />
      </div>
    </div>

    <!-- Companies -->
    <div
      v-if="$_.get(frontpageData, 'companies[0]', false)"
      class="q-mx-auto stl-content-container stl-content-container--xlarge q-mt-xl relative-position"
    >
      <div class="flex column items-center justify-center">
        <AppContent
          tag="h2"
          class="q-ma-none text-h4 text-weight-bold q-mt-xl text-center"
          path="pages.home.companies_title"
        />
        <AppContent
          class="text-subtitle2 text-grey-6 q-mt-sm text-center"
          path="pages.home.companies_subtitle"
        />
        <div class="q-mt-md q-mb-xl row q-pt-md full-width">
          <div
            v-for="company of frontpageData.companies"
            :key="company.id"
            class="col-12 col-lg-3 col-md-4 col-sm-6 q-pa-xs"
          >
            <CompanyCard
              class="hc-link"
              :asset="company"
            />
          </div>
        </div>
        <AppContent
          class="text-body1 text-grey-6 q-my-md text-center"
          path="pages.home.companies_cta"
        />
        <HCBigButton
          label="pages.home.companies_button"
          :to="{ name: 'searchCompanies' }"
        />
      </div>
    </div>

    <!-- Articles -->
    <div
      v-if="$_.get(frontpageData, 'articles[0]', false)"
      class="q-mx-auto stl-content-container stl-content-container--xlarge q-mt-xl q-mb-xl relative-position"
    >
      <div class="flex column items-center justify-center">
        <AppContent
          tag="h2"
          class="q-ma-none text-h4 text-weight-bold q-mt-xl text-center"
          path="pages.home.media_title"
        />
        <AppContent
          class="text-subtitle2 text-grey-6 q-mt-sm text-center"
          path="pages.home.media_subtitle"
        />
        <div class="q-mt-md q-mb-xl row q-pt-md full-width">
          <div
            v-for="article of frontpageData.articles"
            :key="article.id"
            class="col-12 col-md-4 col-sm-6 q-pa-xs"
          >
            <ArticleCard
              class="hc-link"
              :article="article"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- ComptaCapsule -->
    <div
      v-if="$_.get(frontpageData, 'capsules[0]', false)"
      class="q-mx-auto stl-content-container stl-content-container--xlarge q-mt-xl q-mb-xl relative-position"
    >
      <div class="flex column items-center justify-center">
        <h2 class="q-ma-none">
          <img
            :src="cdnImg($t('images.comptaCapsule'))"
            loading="lazy"
            alt="ComptaCapsule"
          >
        </h2>
        <AppContent
          class="text-subtitle2 text-grey-6 q-mt-sm text-center"
          path="pages.home.capsule_title"
        />
        <div class="q-mt-md q-mb-xl row q-pt-md full-width">
          <div
            v-for="capsule of frontpageData.capsules"
            :key="capsule.id"
            class="col-12 col-md-6 q-pa-xs"
          >
            <CapsuleCard
              class="hc-link"
              :capsule="capsule"
            />
          </div>
        </div>
      </div>
    </div>
  </QPage>
</template>

<script>
import OfferCard from 'hc-core/components/cards/offer-card'
import ArticleCard from 'hc-core/components/cards/article-card'
import CapsuleCard from 'hc-core/components/cards/capsule-card'
import CompanyCard from 'hc-core/components/cards/company-card'

export default {
  preFetch ({ store }) {
    return store.dispatch('search/fetchFrontpageData')
  },
  components: { OfferCard, ArticleCard, CapsuleCard, CompanyCard },
  data () {
    return {
      query: null,
    }
  },
  computed: {
    frontpageData () { return this.$store.getters['search/frontpageData'] },
    companyCategories () { return this.getCustomAttributeValues('categories').filter((c) => !['all', 'countryside', 'grand-est'].includes(c.value)).slice(0, 12) },
    landingRecruitAd () { return this.$_.get(this.$store, 'getters[\'content/landingRecruitAd\']', null) }
  },
  mounted () {
    // Not essential so its deferred
    if (!this.landingRecruitAd) {
      const today = new Date().toISOString().slice(0, 10)
      this.$store.dispatch('content/list', {
        collection: 'platformAds',
        nbResultsPerPage: 20,
        rawFilters: [`fields.startDate GTE ${today}T00:00:00.000Z`, `fields.endDate LTE ${today}T23:59:59.999Z`],
        metadata: { active: true }
      })
    }
  },
  methods: {
    fireSearch (category = false) {
      if (category) this.$router.push({ name: 'searchCompanies', query: { c: category } })
      else this.$router.push({ name: 'searchOffers', query: { s: this.query } })
    },
    async handleAdClick (platformAd) {
      if (!platformAd) return
      if (this.$_.get(platformAd, 'fields.link', false)) window.open(platformAd.fields.link, '_blank')
      try {
        // Dispatch update for analytics
        await this.$store.dispatch('common/sendCustomEvent', {
          type: 'public_update_entry',
          objectId: platformAd.id,
          emitterId: 'happycab-v3',
          metadata: {
            entryKeyToUpdate: '_analytics.clicks',
            entryValueToAdd: 1
          }
        })
      } catch (e) { /* No warning since not vital */ }
    }
  }
}
</script>

<style lang="sass" scoped>
.companyCategory
  width: 100%
  .q-img__content > div
    transition: 0.4s ease all
  &:hover
    .q-img__content > div
      background: rgba(0, 0, 0, 0)
</style>
